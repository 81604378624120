import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import jwt_decode from "jwt-decode";

interface AuthState {
  isLoading: boolean;
  listModuleAccess: any[];
  token: string | null;
  isLoggin: boolean;
  isShowPassword: boolean;
  isShowConfPassword: boolean;
  name: string;
}

const initialState: AuthState = {
  isLoading: false,
  token: localStorage.getItem(process.env.REACT_APP_AUTH as string),
  isLoggin: false,
  listModuleAccess: [],
  isShowPassword: false,
  isShowConfPassword: false,
  name: "",
};

const authSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    setUserData: (state) => {
      if (state.token !== null) {
        const splitToken = state.token.split(" ");
        const decodeToken: any = jwt_decode(splitToken[1]);
        state.isLoggin = true;
        state.name = decodeToken.username;
        state.listModuleAccess = decodeToken.listModuleAccess;
      }
    },
    showPassword: (state) => {
      state.isShowPassword = !state.isShowPassword;
    },
    logout: (state) => {
      localStorage.removeItem(process.env.REACT_APP_AUTH as string);
      return {
        ...state,
        name: "",
        subname: "",
        token: null,
        isLoggin: false,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      return {
        ...state,
        isLoading: true,
        isShowPassword: false,
      };
    });
    builder.addCase(login.fulfilled, (state, { payload }) => {
      const { Authorization } = payload;
      localStorage.setItem(process.env.REACT_APP_AUTH as string, Authorization);
      return {
        ...state,
        isLoading: false,
        token: Authorization,
        isLoggin: true,
      };
    });
    builder.addCase(login.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
        isShowPassword: false,
      };
    });
  },
});

export const { setUserData, showPassword, logout } = authSlice.actions;
export default authSlice.reducer;

export const login = createAsyncThunk("auth/login", async (value: any, { dispatch }) => {
  try {
    const { data, status } = await axios.post(`${process.env.REACT_APP_API_URL}/login`, value);
    if (status === 200) dispatch(setUserData());
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
});
