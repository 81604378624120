import React from "react";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Routes, Route } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import routes from "src/router";

import LocalizationProvider from "@mui/lab/LocalizationProvider";
import MinimalLayout from "src/components/MinimalLayout";
import LoginPage from "src/content/auth/signin_page";
import PublicRoutes from "./PublicRoute";
import AuthRoutes from "./AuthRoutes";
import ThemeProvider from "./theme/ThemeProvider";
import Status404 from "src/content/Status404";

import { filterAccess } from "src/utils/filter_module";
// redux
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { setUserData } from "./redux/auth";

const App = () => {
  const dispatch = useAppDispatch();
  const { isLoggin } = useAppSelector((state) => state.auth);
  const listRoutes = React.useRef<any[]>([]);

  const filterRoute = () => {
    const listRoute = filterAccess(routes);
    listRoutes.current = listRoute;
  };

  React.useEffect(() => {
    dispatch(setUserData());
    filterRoute();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggin]);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        <Routes>
          <Route path="/" element={<MinimalLayout />}>
            {/* Public Routes */}
            <Route element={<PublicRoutes isLoggin={isLoggin} />}>
              <Route path="signin" element={<LoginPage />} />
            </Route>

            {/* Private Routes */}
            <Route path="/" element={<AuthRoutes isLoggin={isLoggin} />}>
              {listRoutes.current.map((route, i) =>
                route.children ? (
                  <Route key={i} path={route.path} element={route.element}>
                    {route.children.map((child: any, j: number) => (
                      <Route key={j} path={child.path} element={child.element} />
                    ))}
                  </Route>
                ) : (
                  <Route key={i} path={route.path} element={route.element} />
                )
              )}
            </Route>
          </Route>
          <Route path="*" element={<Status404 isLoggin={isLoggin} />} />
        </Routes>
      </LocalizationProvider>
    </ThemeProvider>
  );
};
export default App;
