import App from "./App";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";

import "nprogress/nprogress.css";
import "./styles/index.scss";
import { SidebarProvider } from "./contexts/SidebarContext";

ReactDOM.render(
  <HelmetProvider>
    <BrowserRouter>
      <Provider store={store}>
        <SidebarProvider>
          <App />
        </SidebarProvider>
      </Provider>
    </BrowserRouter>
  </HelmetProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
