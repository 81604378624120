import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "src/utils/axios";

interface state {
  listClients: any[];
  listRoles: any[];
  editMode: boolean;
  isLoading: boolean;
  isDisabled: boolean;
  isReadonly: boolean;
  showModal: boolean;
  isQrModal: boolean;
  id: string;
  editValue: {};
  qrValue: {
    code: string;
    resident: string;
    residentName: string;
  };
  image: {
    imageDisplay: any;
    imageName: any;
    imageValue: any;
  };
  isShowPassword: boolean;
  isShowConfPassword: boolean;
  count: number;
  currentPage: number;
  totalItem: number;
}

const initialState: state = {
  listClients: [],
  listRoles: [],
  editMode: false,
  isLoading: true,
  isDisabled: false,
  isReadonly: false,
  showModal: false,
  isQrModal: false,
  id: "",
  editValue: {},
  qrValue: {
    code: "",
    resident: "",
    residentName: "",
  },
  image: {
    imageDisplay: null,
    imageName: null,
    imageValue: null,
  },
  isShowPassword: false,
  isShowConfPassword: false,
  count: 1,
  currentPage: 1,
  totalItem: 20,
};

const clientSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    showPopupModal: (state) => {
      state.showModal = !state.showModal;
      state.id = "";
      state.editMode = false;
      state.editValue = {};
      state.image = {
        imageDisplay: null,
        imageName: null,
        imageValue: null,
      };
    },
    imageHandler: (state, { payload }) => {
      state.image = {
        imageDisplay: URL.createObjectURL(payload),
        imageName: payload.name,
        imageValue: payload,
      };
    },
    showQrModal: (state, { payload }) => {
      const findData = state.listClients.find((prev) => prev.id === payload);
      state.isQrModal = !state.isQrModal;
      state.showModal = !state.showModal;
      if (findData !== undefined) {
        state.qrValue = {
          code: findData.uuid,
          resident: findData.username,
          residentName: findData.name,
        };
      }
    },
    updateClients: (state, { payload }) => {
      const findClient = state.listClients.find((data: any) => data.id === payload);
      if (findClient !== undefined) {
        state.showModal = !state.showModal;
        state.id = findClient.id;
        state.editMode = true;
        state.editValue = findClient;
        state.image = {
          imageDisplay: process.env.REACT_APP_IMG_URL + findClient.image,
          imageName: findClient.image,
          imageValue: findClient.image,
        };
      }
    },
    onChangePage: (state, { payload }) => {
      state.currentPage = payload;
    },
    handleShowPass: (state, { payload }) => {
      if (payload === "password") state.isShowPassword = !state.isShowPassword;

      if (payload === "confPassword") state.isShowConfPassword = !state.isShowConfPassword;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllClients.fulfilled, (state, { payload }) => {
        const { data, count, roles } = payload;
        return {
          ...state,
          isLoading: false,
          listClients: data,
          listRoles: roles,
          count,
        };
      })
      .addCase(getAllClients.rejected, (state) => {
        return {
          ...state,
          isLoading: false,
          count: 1,
        };
      })
      .addCase(createClient.pending, (state) => {
        state.isDisabled = true;
        state.isReadonly = true;
      })
      .addCase(createClient.fulfilled, (state) => {
        state.isDisabled = false;
        state.isReadonly = false;
      })
      .addCase(createClient.rejected, (state) => {
        state.isDisabled = false;
        state.isReadonly = false;
      })
      .addCase(editClients.pending, (state) => {
        state.isDisabled = true;
        state.isReadonly = true;
      })
      .addCase(editClients.fulfilled, (state, { payload }) => {
        const { data } = payload;
        const index = state.listClients.findIndex((prev) => prev.id === data.id);
        state.isDisabled = false;
        state.listClients[index] = data;
        state.isReadonly = false;
      })
      .addCase(editClients.rejected, (state) => {
        state.isDisabled = false;
        state.isReadonly = false;
      })
      .addCase(deleteClients.fulfilled, (state, { meta }) => {
        const { arg } = meta;
        const newData = state.listClients.filter((data: any) => data.id !== arg);
        return {
          ...state,
          listClients: newData,
        };
      });
  },
});
export const {
  showPopupModal,
  showQrModal,
  updateClients,
  onChangePage,
  handleShowPass,
  imageHandler,
} = clientSlice.actions;
export default clientSlice.reducer;

export const getAllClients = createAsyncThunk(
  "getAllClient",
  async (data: { totalItem: number; currentPage: number }) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}/clients?totalItem=${data.totalItem}&page=${data.currentPage}`
      );
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
);

export const createClient = createAsyncThunk("createClient", async (data: any, _) => {
  try {
    const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/clients`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const editClients = createAsyncThunk(
  "editClients",
  async (data: { id: string; value: any }, _) => {
    try {
      const response = await axiosInstance.put(
        `${process.env.REACT_APP_API_URL}/clients/${data.id}`,
        data.value,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
);

export const deleteClients = createAsyncThunk("deleteClients", async (id: string, _) => {
  try {
    const response = await axiosInstance.delete(`${process.env.REACT_APP_API_URL}/clients/${id}`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});
