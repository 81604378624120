import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "src/utils/axios";

interface State {
  listRole: any[];
  editMode: boolean;
  isLoading: boolean;
  isDisabled: boolean;
  showModal: boolean;
  id: string;
  editValue: {};
}

const initialState: State = {
  listRole: [],
  editMode: false,
  isLoading: true,
  isDisabled: false,
  showModal: false,
  id: "",
  editValue: {},
};

const roleSlice = createSlice({
  name: "client roles",
  initialState,
  reducers: {
    showPopupModal: (state) => {
      state.showModal = !state.showModal;
      state.id = "";
      state.editMode = false;
      state.editValue = {};
    },
    updatedRole: (state, { payload }) => {
      const [findRole] = state.listRole.filter((data: any) => data.id === payload);
      if (findRole !== undefined) {
        state.showModal = !state.showModal;
        state.id = findRole.id;
        state.editMode = true;
        state.editValue = findRole;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllClientRole.fulfilled, (state, { payload }) => {
        const { data } = payload;
        return {
          ...state,
          isLoading: false,
          listRole: data,
        };
      })
      .addCase(getAllClientRole.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(createClientRole.pending, (state) => {
        state.isDisabled = true;
      })
      .addCase(createClientRole.fulfilled, (state, { payload }) => {
        const { data } = payload;
        state.isDisabled = false;
        state.listRole = [...state.listRole, data];
      })
      .addCase(createClientRole.rejected, (state) => {
        state.isDisabled = false;
      })
      .addCase(editClientRole.pending, (state) => {
        state.isDisabled = true;
      })
      .addCase(editClientRole.fulfilled, (state, { meta }) => {
        const { name, id } = meta.arg;
        const index = state.listRole.findIndex((data: any) => data.id === id);
        const newValue = { id, name };
        state.listRole[index] = newValue;
        state.isDisabled = false;
      })
      .addCase(editClientRole.rejected, (state) => {
        state.isDisabled = false;
      })
      .addCase(deleteClientRole.fulfilled, (state, { meta }) => {
        const { arg } = meta;
        const newData = state.listRole.filter((data: any) => data.id !== arg);
        state.listRole = newData;
      });
  },
});
export const { showPopupModal, updatedRole } = roleSlice.actions;
export default roleSlice.reducer;

export const getAllClientRole = createAsyncThunk("getAllClientRole", async () => {
  try {
    const response = await axiosInstance.get(`/client-roles`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const createClientRole = createAsyncThunk("createClientRole", async (data: any) => {
  try {
    const response = await axiosInstance.post(`/client-roles`, data);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const editClientRole = createAsyncThunk("editClientRole", async (data: any) => {
  try {
    const response = await axiosInstance.put(`/client-roles`, data);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const deleteClientRole = createAsyncThunk("deleteClientRole", async (id: string) => {
  try {
    const response = await axiosInstance.delete(`/client-roles/${id}`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});
