import loadable from "@loadable/component";
import { routePath } from "./layouts/SidebarLayout/Sidebar/SidebarMenu/items";

import SidebarLayout from "src/layouts/SidebarLayout";

const Dashboards = loadable(() => import("src/content/dashboards"));

const Roles = loadable(() => import("src/content/management-teams/roles"));
const Users = loadable(() => import("src/content/management-teams/teams"));
const Permission = loadable(() => import("src/content/management-teams/permission"));
const PermissionAccess = loadable(
  () => import("src/content/management-teams/permission/role-permission")
);

const Banners = loadable(() => import("src/content/management-clients/banners_backup"));
const BannerForm = loadable(
  () => import("src/content/management-clients/banners_backup/client-banners")
);

const ClientRoles = loadable(() => import("src/content/management-clients/roles"));
const ClientPermission = loadable(() => import("src/content/management-clients/permission"));
const ClientRolePermission = loadable(
  () => import("src/content/management-clients/permission/role-permission")
);
const Clients = loadable(() => import("src/content/management-clients/client"));
const Services = loadable(() => import("src/content/management-clients/service"));

const routes = [
  {
    path: "/",
    element: <SidebarLayout />,
    children: [
      {
        id: "allow",
        path: routePath.DASHBOARD.pathName,
        element: <Dashboards />,
      },

      // management module
      {
        id: routePath.ROLES.id,
        path: routePath.ROLES.pathName,
        element: <Roles />,
      },
      {
        id: routePath.PERMISSION.id,
        path: routePath.PERMISSION.pathName,
        element: <Permission />,
      },
      {
        id: routePath.ROLE_PERMISSION.id,
        path: routePath.ROLE_PERMISSION.pathName,
        element: <PermissionAccess />,
      },

      // management employees
      {
        id: routePath.EMPLOYEES.id,
        path: routePath.EMPLOYEES.pathName,
        element: <Users />,
      },

      // management Client
      {
        id: routePath.BANNER.id,
        path: routePath.BANNER.pathName,
        element: <Banners />,
      },
      // banner v2
      // {
      //   id: routePath.BANNER_FORM.id,
      //   path: routePath.BANNER_FORM.pathName,
      //   element: <BannerForm />,
      // },
      // backup banner v1
      {
        id: routePath.CLIENT_BANNER.id,
        path: routePath.CLIENT_BANNER.pathName,
        element: <BannerForm />,
      },
      {
        id: routePath.CLIENT.id,
        path: routePath.CLIENT.pathName,
        element: <Clients />,
      },
      {
        id: routePath.CLIENT_ROLES.id,
        path: routePath.CLIENT_ROLES.pathName,
        element: <ClientRoles />,
      },
      {
        id: routePath.CLIENT_PERMISSION.id,
        path: routePath.CLIENT_PERMISSION.pathName,
        element: <ClientPermission />,
      },
      {
        id: routePath.CLIENT_ROLE_PERMISSION.id,
        path: routePath.CLIENT_ROLE_PERMISSION.pathName,
        element: <ClientRolePermission />,
      },
      {
        id: routePath.SERVICE.id,
        path: routePath.SERVICE.pathName,
        element: <Services />,
      },
    ],
  },
];

export default routes;
