import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "src/utils/axios";

interface State {
  listService: any[];
  editMode: boolean;
  isLoading: boolean;
  isDisabled: boolean;
  showModal: boolean;
  id: string;
  editValue: {};
  image: {
    imageDisplay: any;
    imageName: any;
    imageValue: any;
  };
  amount: number;
}

const initialState: State = {
  listService: [],
  editMode: false,
  isLoading: true,
  isDisabled: false,
  showModal: false,
  id: "",
  editValue: {},
  image: {
    imageDisplay: null,
    imageName: null,
    imageValue: null,
  },
  amount: 0,
};

const slice = createSlice({
  name: "services",
  initialState,
  reducers: {
    showPopupModal: (state) => {
      state.showModal = !state.showModal;
      state.id = "";
      state.editMode = false;
      state.editValue = {};
      state.amount = 0;
      state.image = {
        imageDisplay: null,
        imageName: null,
        imageValue: null,
      };
    },
    imageHandler: (state: State, { payload }) => {
      state.image = {
        imageDisplay: URL.createObjectURL(payload),
        imageName: payload.name,
        imageValue: payload,
      };
    },
    handleAmountInput: (state: State, { payload }) => {
      state.amount = +payload;
    },
    updatedService: (state: State, { payload }) => {
      const [findService] = state.listService.filter((data: any) => data.id === payload);
      if (findService !== undefined) {
        state.showModal = !state.showModal;
        state.id = findService.id;
        state.editMode = true;
        state.editValue = findService;
        state.amount = +findService.price;
        state.image = {
          imageDisplay: process.env.REACT_APP_IMG_URL + findService.image,
          imageName: findService.image,
          imageValue: findService.image,
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllService.fulfilled, (state, { payload }) => {
        const { data } = payload;
        state.isLoading = false;
        state.listService = data;
      })
      .addCase(getAllService.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(createService.pending, (state) => {
        state.isDisabled = true;
      })
      .addCase(createService.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.isDisabled = false;
        state.listService = [...state.listService, data];
      })
      .addCase(createService.rejected, (state) => {
        state.isDisabled = false;
      })
      .addCase(editService.pending, (state) => {
        state.isDisabled = true;
      })
      .addCase(editService.fulfilled, (state, { payload }) => {
        const { data } = payload;
        const index = state.listService.findIndex((prev: any) => prev.id === data.id);
        state.isDisabled = false;
        state.listService[index] = data;
      })
      .addCase(editService.rejected, (state) => {
        state.isDisabled = false;
      })
      .addCase(deleteService.fulfilled, (state, action) => {
        const { arg } = action.meta;
        const newData = state.listService.filter((data: any) => data.id !== arg);
        return {
          ...state,
          listService: newData,
        };
      });
  },
});
export const { showPopupModal, updatedService, imageHandler, handleAmountInput } = slice.actions;
export default slice.reducer;

export const getAllService = createAsyncThunk("getAllService", async () => {
  try {
    const response = await axiosInstance.get(`/services`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const createService = createAsyncThunk("createService", async (data: any, _) => {
  try {
    const response = await axiosInstance.post(`/services`, data);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const editService = createAsyncThunk(
  "editService",
  async (data: { id: string; value: any }, _) => {
    try {
      const response = await axiosInstance.put(`/services/${data.id}`, data.value);
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
);

export const deleteService = createAsyncThunk("deleteService", async (id: string, _) => {
  try {
    const response = await axiosInstance.delete(`/services/${id}`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});
