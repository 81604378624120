import jwt_decode from "jwt-decode";

export const filterModules = (listModules: any[], moduleId: string) =>
  listModules.filter((data: any) => data.moduleId === moduleId);

export const filterAccess = (routes: any[]): any[] => {
  let listRoutes = [];
  const getToken = localStorage.getItem(process.env.REACT_APP_AUTH as string);
  if (getToken === null) return [];

  const splitToken = getToken.split(" ");
  const { listModuleAccess }: any = jwt_decode(splitToken[1]);

  for (const { path: parentPath, element: parentElement, children } of routes) {
    for (const { moduleId, canRead } of listModuleAccess) {
      // if have a child
      if (children) {
        for (const { id, path, element } of children) {
          const index = listRoutes.findIndex((prev) => prev.path === parentPath);

          if (moduleId === id && canRead === 1) {
            if (index === -1) {
              listRoutes.unshift({
                path: parentPath,
                element: parentElement,
                children: [{ id, path, element }],
              });
            } else {
              listRoutes[index].children.push({ id, path, element });
            }
          }

          if (id === "allow" && index === -1) {
            listRoutes.unshift({
              path: parentPath,
              element: parentElement,
              children: [{ id, path, element }],
            });
          }
        }
      }
    }
  }

  return listRoutes;
};
