import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "src/utils/axios";
import dayjs, { Dayjs } from "dayjs";

interface State {
  listClients: any[];
  listBannerClients: any[];
  editMode: boolean;
  isLoading: boolean;
  isDisabled: boolean;
  showModal: boolean;
  id: string;
  editValue: {};
  image: {
    imageDisplay: any;
    imageName: any;
    imageValue: any;
  };
  startDate: string | Dayjs;
  endDate: string | Dayjs;
  count: number;
  currentPage: number;
  totalItem: number;
}

const initialState: State = {
  listClients: [],
  listBannerClients: [],
  editMode: false,
  isLoading: true,
  isDisabled: false,
  showModal: false,
  id: "",
  editValue: {},
  image: {
    imageDisplay: null,
    imageName: null,
    imageValue: null,
  },
  startDate: dayjs(new Date()),
  endDate: dayjs(new Date()),
  count: 1,
  currentPage: 1,
  totalItem: 20,
};

const slice = createSlice({
  name: "banner",
  initialState,
  reducers: {
    showPopupModal: (state) => {
      state.showModal = !state.showModal;
      state.id = "";
      state.editMode = false;
      state.editValue = {};
      state.image = {
        imageDisplay: null,
        imageName: null,
        imageValue: null,
      };
      state.startDate = Date();
      state.endDate = Date();
    },
    onChangePage: (state, { payload }) => {
      state.currentPage = payload;
    },
    updateBanner: (state, { payload }) => {
      const findBanner = state.listBannerClients.find((data: any) => data.id === payload);
      if (findBanner !== undefined) {
        state.showModal = !state.showModal;
        state.editMode = true;
        state.id = findBanner.id;
        state.editValue = findBanner;
        state.startDate = dayjs(findBanner.startDate);
        state.endDate = dayjs(findBanner.endDate);
        state.image = {
          imageDisplay: process.env.REACT_APP_IMG_URL + findBanner.image,
          imageName: findBanner.image,
          imageValue: findBanner.image,
        };
      }
    },
    imageHandler: (state, { payload }) => {
      state.image = {
        imageDisplay: URL.createObjectURL(payload),
        imageName: payload.name,
        imageValue: payload,
      };
    },
    handleDate: (
      state,
      action: PayloadAction<{ dateValue: string | Dayjs; inputName: string }>
    ) => {
      const { inputName, dateValue } = action.payload;
      if (inputName === "startDate") {
        state.startDate = dateValue;
        state.endDate = dateValue;
      } else {
        state.endDate = dateValue;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllClients.fulfilled, (state, { payload }) => {
        const { data, count } = payload;
        return {
          ...state,
          isLoading: false,
          listClients: data,
          count,
        };
      })
      .addCase(getAllClients.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getBannerByClient.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBannerByClient.fulfilled, (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          isLoading: false,
          listBannerClients: data,
        };
      })
      .addCase(getBannerByClient.rejected, (state) => {
        state.isLoading = false;
        state.listBannerClients = [];
      })
      .addCase(createBanner.pending, (state) => {
        state.isDisabled = true;
      })
      .addCase(createBanner.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.isDisabled = false;
        state.listBannerClients = [...state.listBannerClients, data];
      })
      .addCase(createBanner.rejected, (state) => {
        state.isDisabled = false;
      })
      .addCase(editBanner.pending, (state) => {
        state.isDisabled = true;
      })
      .addCase(editBanner.fulfilled, (state, { payload, meta }) => {
        const { id } = meta.arg;
        const { data } = payload;

        const index = state.listBannerClients.findIndex((data: any) => data.id === id);
        state.isDisabled = false;
        state.listBannerClients[index] = data;
      })
      .addCase(editBanner.rejected, (state) => {
        state.isDisabled = false;
      })
      .addCase(deleteBanner.fulfilled, (state, action) => {
        const { arg } = action.meta;
        const newData = state.listBannerClients.filter((data: any) => data.id !== arg);
        state.listBannerClients = newData;
      });
  },
});
export const { showPopupModal, updateBanner, onChangePage, handleDate, imageHandler } =
  slice.actions;
export default slice.reducer;

export const getAllClients = createAsyncThunk(
  "getAllClients",
  async (data: { totalItem: number; currentPage: number }) => {
    try {
      const response = await axiosInstance.get(
        `/client-banner?totalItem=${data.totalItem}&page=${data.currentPage}`
      );
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
);

export const getBannerByClient = createAsyncThunk("getBannerByClient", async (id: string) => {
  try {
    const response = await axiosInstance.get(`/banner/${id}`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const createBanner = createAsyncThunk(
  "createBanner",
  async (data: { value: any; clientId: string }, _) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}/banner/${data.clientId}`,
        data.value,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
);

export const editBanner = createAsyncThunk(
  "editBanner",
  async (data: { id: string; clientId: string; value: any }, _) => {
    try {
      const response = await axiosInstance.put(`/banner/${data.id}/${data.clientId}`, data.value, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
);

export const deleteBanner = createAsyncThunk("deleteBanner", async (id: string, _) => {
  try {
    const response = await axiosInstance.delete(`/banner/${id}`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});
