import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const authotizationKey = process.env.REACT_APP_AUTH as string;
    const token = localStorage.getItem(authotizationKey);

    if (token && config.headers) {
      config.headers[authotizationKey] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      const authotizationKey = process.env.REACT_APP_AUTH as string;
      localStorage.removeItem(authotizationKey);
      setTimeout(() => {
        window.location.href = `${process.env.REACT_APP_URL}/signin`;
      }, 200);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
