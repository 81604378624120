import { useEffect } from "react";
import { Box, Typography, Container } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { routePath } from "src/layouts/SidebarLayout/Sidebar/SidebarMenu/items";

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
);

type Props = {
  isLoggin: boolean;
};

function Status404({ isLoggin }: Props) {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate(isLoggin ? routePath.SIGNIN.pathName : routePath.DASHBOARD.pathName, {
        replace: true,
      });
    }, 1000);

    return () => {
      clearTimeout(timer);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggin]);

  return (
    <>
      <Helmet>
        <title>Status - 404</title>
      </Helmet>
      <MainContent>
        <Container maxWidth="md">
          <Box textAlign="center">
            <img alt="404" height={180} src="/static/images/status/404.svg" />
            <Typography variant="h2" sx={{ my: 2 }}>
              The page you were looking for doesn't exist.
            </Typography>
            <Typography variant="h4" color="text.secondary" fontWeight="normal" sx={{ mb: 4 }}>
              It's on us, we moved the content to a different page. The search below should help!
            </Typography>
          </Box>
        </Container>
      </MainContent>
    </>
  );
}

export default Status404;
