import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "src/utils/axios";

interface state {
  listProvinces: any[];
  provinceLoading: boolean;
  provinceOpen: boolean;
  provinceInput: {
    value: string;
    input: string;
  };
  hasRegencies: boolean;

  listRegencies: any[];
  regencyLoading: boolean;
  regencyOpen: boolean;
  regencyInput: {
    value: string;
    input: string;
  };
  hasDistrict: boolean;

  listDistricts: any[];
  districtLoading: boolean;
  districtOpen: boolean;
  districtInput: {
    value: string;
    input: string;
  };
  hasVillage: boolean;

  listVillages: any[];
  villageLoading: boolean;
  villageOpen: boolean;
  villageInput: {
    value: string;
    input: string;
  };
}

const initialState: state = {
  listProvinces: [],
  provinceLoading: false,
  provinceOpen: false,
  provinceInput: {
    value: "",
    input: "",
  },
  hasRegencies: false,

  listRegencies: [],
  regencyLoading: false,
  regencyOpen: false,
  regencyInput: {
    value: "",
    input: "",
  },
  hasDistrict: false,

  listDistricts: [],
  districtLoading: false,
  districtOpen: false,
  districtInput: {
    value: "",
    input: "",
  },
  hasVillage: false,

  listVillages: [],
  villageLoading: false,
  villageOpen: false,
  villageInput: {
    value: "",
    input: "",
  },
};

const slice = createSlice({
  name: "cities",
  initialState,
  reducers: {
    handleSelect: (state, { payload }) => {
      const { key, value } = payload;
      if (key === "province") {
        state.provinceInput = {
          value: value,
          input: value,
        };
      }

      if (key === "regency") {
        state.regencyInput = {
          value: value,
          input: value,
        };
      }

      if (key === "district") {
        state.districtInput = {
          value: value,
          input: value,
        };
      }
      if (key === "village") {
        state.villageInput = {
          value: value,
          input: value,
        };
      }
    },
    handleInput: (state, { payload }) => {
      const { key, value } = payload;
      if (key === "province") {
        state.provinceInput = {
          ...state.provinceInput,
          input: value,
        };
        if (value === "") state.listProvinces = [];
      }

      if (key === "regency") {
        state.regencyInput = {
          ...state.regencyInput,
          input: value,
        };
        if (value === "") state.listRegencies = [];
      }

      if (key === "district") {
        state.districtInput = {
          ...state.districtInput,
          input: value,
        };
        if (value === "") state.listDistricts = [];
      }

      if (key === "village") {
        state.villageInput = {
          ...state.villageInput,
          input: value,
        };
        if (value === "") state.listVillages = [];
      }
    },
    handleOpen: (state, { payload }) => {
      const { key } = payload;
      if (key === "province") state.provinceOpen = !state.provinceOpen;
      if (key === "regency") state.regencyOpen = !state.regencyOpen;
      if (key === "district") state.districtOpen = !state.districtOpen;
      if (key === "village") state.villageOpen = !state.villageOpen;
    },
    resetProvince: (state) => {
      state.hasRegencies = false;
      state.hasDistrict = false;
      state.hasVillage = false;
      state.listProvinces = [];
      state.listRegencies = [];
      state.listDistricts = [];
      state.listVillages = [];
      state.provinceInput = {
        input: "",
        value: "",
      };
      state.regencyInput = {
        input: "",
        value: "",
      };
      state.districtInput = {
        input: "",
        value: "",
      };
      state.villageInput = {
        input: "",
        value: "",
      };
    },
    resetRegency: (state) => {
      state.hasDistrict = false;
      state.hasVillage = false;
      state.listRegencies = [];
      state.listDistricts = [];
      state.listVillages = [];
      state.regencyInput = {
        input: "",
        value: "",
      };
      state.districtInput = {
        input: "",
        value: "",
      };
      state.villageInput = {
        input: "",
        value: "",
      };
    },
    resetDistrict: (state) => {
      state.hasVillage = false;
      state.listDistricts = [];
      state.listVillages = [];
      state.districtInput = {
        input: "",
        value: "",
      };
      state.villageInput = {
        input: "",
        value: "",
      };
    },
    resetVillage: (state) => {
      state.listVillages = [];
      state.villageInput = {
        input: "",
        value: "",
      };
    },
    initialInput: (state, { payload }) => {
      const { province, regency, district, village } = payload;
      state.hasRegencies = regency !== null ? true : false;
      state.hasDistrict = district !== null ? true : false;
      state.hasVillage = village !== null ? true : false;

      state.provinceInput = {
        input: province === null ? "" : province,
        value: province === null ? "" : province,
      };
      state.regencyInput = {
        input: regency === null ? "" : regency,
        value: regency === null ? "" : regency,
      };
      state.districtInput = {
        input: district === null ? "" : district,
        value: district === null ? "" : district,
      };
      state.villageInput = {
        input: village === null ? "" : village,
        value: village === null ? "" : village,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProvinces.pending, (state) => {
        state.provinceLoading = true;
      })
      .addCase(getProvinces.fulfilled, (state, { payload }) => {
        const { data } = payload;
        state.listProvinces = data;
        state.provinceLoading = false;
      })
      .addCase(getProvinces.rejected, (state) => {
        state.provinceLoading = false;
      })
      .addCase(checkRegencies.fulfilled, (state, { payload }) => {
        const { data } = payload;
        state.hasRegencies = data;
      })
      .addCase(getRegencies.pending, (state) => {
        state.regencyLoading = true;
      })
      .addCase(getRegencies.fulfilled, (state, { payload }) => {
        const { data } = payload;
        state.listRegencies = data;
        state.regencyLoading = false;
      })
      .addCase(getRegencies.rejected, (state) => {
        state.regencyLoading = false;
      })
      .addCase(checkDistricts.fulfilled, (state, { payload }) => {
        const { data } = payload;
        state.hasDistrict = data;
      })
      .addCase(getDistricts.pending, (state) => {
        state.districtLoading = true;
      })
      .addCase(getDistricts.fulfilled, (state, { payload }) => {
        const { data } = payload;
        state.listDistricts = data;
        state.districtLoading = false;
      })
      .addCase(getDistricts.rejected, (state) => {
        state.districtLoading = false;
      })
      .addCase(checkVillages.fulfilled, (state, { payload }) => {
        const { data } = payload;
        state.hasVillage = data;
      })
      .addCase(getVillages.pending, (state) => {
        state.villageLoading = true;
      })
      .addCase(getVillages.fulfilled, (state, { payload }) => {
        const { data } = payload;
        state.listVillages = data;
        state.villageLoading = false;
      })
      .addCase(getVillages.rejected, (state) => {
        state.villageLoading = false;
      });
  },
});

export const {
  handleInput,
  handleSelect,
  handleOpen,
  resetProvince,
  resetRegency,
  resetDistrict,
  resetVillage,
  initialInput,
} = slice.actions;
export default slice.reducer;

export const getProvinces = createAsyncThunk("getProvinces", async (query: string) => {
  try {
    const response = await axiosInstance.get(`/provinces?${query}`);
    return response.data;
  } catch (err: any) {
    throw err.response.data;
  }
});

export const checkRegencies = createAsyncThunk("checkRegencies", async (query: string) => {
  try {
    const response = await axiosInstance.get(`/check-regency?${query}`);
    return response.data;
  } catch (err: any) {
    throw err.response.data;
  }
});

export const getRegencies = createAsyncThunk("getRegencies", async (query: string) => {
  try {
    const response = await axiosInstance.get(`/regencies?${query}`);
    return response.data;
  } catch (err: any) {
    throw err.response.data;
  }
});

export const checkDistricts = createAsyncThunk("checkDistricts", async (query: string) => {
  try {
    const response = await axiosInstance.get(`/check-districts?${query}`);
    return response.data;
  } catch (err: any) {
    throw err.response.data;
  }
});

export const getDistricts = createAsyncThunk("getDistricts", async (query: string) => {
  try {
    const response = await axiosInstance.get(`/districts?${query}`);
    return response.data;
  } catch (err: any) {
    throw err.response.data;
  }
});

export const checkVillages = createAsyncThunk("checkVillages", async (query: string) => {
  try {
    const response = await axiosInstance.get(`/check-villages?${query}`);
    return response.data;
  } catch (err: any) {
    throw err.response.data;
  }
});

export const getVillages = createAsyncThunk("getVillages", async (query: string) => {
  try {
    const response = await axiosInstance.get(`/villages?${query}`);
    return response.data;
  } catch (err: any) {
    throw err.response.data;
  }
});
