import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "src/utils/axios";
import dayjs, { Dayjs } from "dayjs";

interface State {
  listState: any[];
  listSelectState: any[];
  listClient: any[];
  listSelectClient: any[];
  listGender: any[];
  listSelectGender: any[];
  listReligion: any[];
  listSelectReligion: any[];
  listStartDateSlot: any[];
  listEndDateSlot: any[];
  listDateOff: any[];
  listBanners: any[];
  editMode: boolean;
  isValidate: boolean;
  isLoading: boolean;
  isStateLoading: boolean;
  isDateLoading: boolean;
  isDisabled: boolean;
  id: string;
  image: {
    imageDisplay: any;
    imageName: any;
    imageValue: any;
  };
  initialDate: Dayjs;
  startDate: Dayjs;
  endDate: Dayjs;
  hasMore: boolean;
  count: number;
  currentPage: number;
  totalItem: number;
}

const initialState: State = {
  listState: [],
  listSelectState: [],
  listClient: [],
  listSelectClient: [],
  listGender: [],
  listSelectGender: [],
  listReligion: [],
  listSelectReligion: [],
  listStartDateSlot: [],
  listEndDateSlot: [],
  listDateOff: [],
  listBanners: [],
  isValidate: false,
  editMode: false,
  isLoading: true,
  isDateLoading: false,
  isStateLoading: false,
  isDisabled: false,
  initialDate: dayjs(),
  id: "",
  image: {
    imageDisplay: null,
    imageName: null,
    imageValue: null,
  },
  startDate: dayjs(new Date()),
  endDate: dayjs(new Date()),
  hasMore: true,
  count: 1,
  currentPage: 0,
  totalItem: 30,
};

const slice = createSlice({
  name: "banner",
  initialState,
  reducers: {
    editAgain: (state) => {
      state.isValidate = false;
      state.listDateOff = [];
    },
    imageHandler: (state, { payload }) => {
      state.image = {
        imageDisplay: URL.createObjectURL(payload),
        imageName: payload.name,
        imageValue: payload,
      };
    },
    handleDate: (state, action: PayloadAction<{ dateValue: Dayjs; inputName: string }>) => {
      const { inputName, dateValue } = action.payload;
      if (inputName === "startDate") {
        state.startDate = dateValue;
        state.endDate = dateValue;
      } else {
        state.endDate = dateValue;
      }
    },
    onSelectProvince: (state, { payload }) => {
      const isExists = state.listSelectState.findIndex(
        (prev) => prev.provinceId === payload.provinceId
      );

      if (isExists === -1) {
        state.listSelectState.push({
          provinceId: payload.provinceId,
          listRegency: [],
        });
      } else {
        state.listSelectState.splice(isExists, 1);
      }
    },
    onSelectRegency: (state, { payload }) => {
      const provIndex = state.listSelectState.findIndex(
        (prev) => prev.provinceId === payload.provinceId
      );

      const regencyIndex = state.listSelectState[provIndex].listRegency.findIndex(
        (prev: any) => prev.regencyId === payload.regencyId
      );

      if (regencyIndex === -1) {
        state.listSelectState[provIndex].listRegency.push({
          regencyId: payload.regencyId,
          listDistrict: [],
        });
      } else {
        state.listSelectState[provIndex].listRegency.splice(regencyIndex, 1);
      }
    },
    onSelectDistrict: (state, { payload }) => {
      const provIndex = state.listSelectState.findIndex(
        (prev) => prev.provinceId === payload.provinceId
      );

      const regencyIndex = state.listSelectState[provIndex].listRegency.findIndex(
        (prev: any) => prev.regencyId === payload.regencyId
      );

      const regencyList = state.listSelectState[provIndex].listRegency[regencyIndex];

      const districtIndex = regencyList.listDistrict.findIndex(
        (prev: any) => prev.districtId === payload.districtId
      );

      if (districtIndex === -1) {
        regencyList.listDistrict.push({
          districtId: payload.districtId,
        });
      } else {
        regencyList.listDistrict.splice(districtIndex, 1);
      }
    },
    onSelectClient: (state, { payload }) => {
      const clientIndex = state.listSelectClient.findIndex((prev) => prev.clientId === payload);

      if (clientIndex === -1) {
        state.listSelectClient.push({ clientId: payload });
      } else {
        state.listSelectClient.splice(clientIndex, 1);
      }
    },
    onSelectGender: (state, { payload }) => {
      const genderIndex = state.listSelectGender.findIndex((prev) => prev.gender === payload);

      if (genderIndex === -1) {
        state.listSelectGender.push({ gender: payload });
      } else {
        state.listSelectGender.splice(genderIndex, 1);
      }
    },
    onSelectReligion: (state, { payload }) => {
      const religionIndex = state.listSelectReligion.findIndex((prev) => prev.religion === payload);

      if (religionIndex === -1) {
        state.listSelectReligion.push({ religion: payload });
      } else {
        state.listSelectReligion.splice(religionIndex, 1);
      }
    },
    cleanData: (state) => {
      state.image = {
        imageDisplay: null,
        imageName: null,
        imageValue: null,
      };
      state.startDate = dayjs(new Date());
      state.endDate = dayjs(new Date());
      state.listSelectClient = [];
      state.listSelectGender = [];
      state.listSelectState = [];
      state.listSelectReligion = [];
    },
    cleanListBanner: (state) => {
      state.currentPage = 0;
      state.listBanners = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initialBannerForm.pending, (state) => {
        state.isStateLoading = true;
      })
      .addCase(initialBannerForm.fulfilled, (state, { payload }) => {
        state.isStateLoading = false;
        state.listReligion = payload.listReligion;
        state.listGender = payload.listGender;
        state.listClient = payload.listClient;
        state.listState = payload.listState;
      })
      .addCase(initialBannerForm.rejected, (state) => {
        state.isStateLoading = false;
      })
      .addCase(getBannerById.pending, (state) => {
        state.isStateLoading = true;
      })
      .addCase(getBannerById.fulfilled, (state, { payload }) => {
        const {
          listReligions,
          listSelectedReligions,
          listClients,
          listSelectedClients,
          listGenders,
          listSelectedGenders,
          listStates,
          listSelectedStates,
          startDate,
          endDate,
          image,
        } = payload;

        state.isStateLoading = false;
        state.image = {
          imageDisplay: process.env.REACT_APP_IMG_URL + image,
          imageName: image,
          imageValue: image,
        };
        state.startDate = dayjs(startDate);
        state.endDate = dayjs(endDate);
        // for initial list data based on categories
        state.listReligion = listReligions;
        state.listGender = listGenders;
        state.listClient = listClients;
        state.listState = listStates;

        // set selected value
        state.listSelectReligion = listSelectedReligions;
        state.listSelectClient = listSelectedClients;
        state.listSelectGender = listSelectedGenders;
        state.listSelectState = listSelectedStates;
      })
      .addCase(getBannerById.rejected, (state) => {
        state.isStateLoading = false;
      })
      .addCase(getAllBanner.fulfilled, (state, { payload }) => {
        const { data } = payload;
        state.isLoading = false;

        if (data.length < state.totalItem) {
          state.hasMore = false;
        }

        if (data.length === state.totalItem) {
          state.currentPage += 1;
        }

        state.listBanners = [...data, ...state.listBanners];
      })
      .addCase(getAllBanner.rejected, (state) => {
        state.isLoading = false;
        state.hasMore = false;
      })
      .addCase(createBanner.pending, (state) => {
        state.isDisabled = true;
      })
      .addCase(createBanner.fulfilled, (state) => {
        state.isDisabled = false;
      })
      .addCase(createBanner.rejected, (state) => {
        state.isDisabled = false;
      })
      .addCase(editBanner.pending, (state) => {
        state.isDisabled = true;
      })
      .addCase(editBanner.fulfilled, (state, { payload, meta }) => {
        const { id } = meta.arg;
        const { data } = payload;

        const index = state.listBanners.findIndex((data: any) => data.id === id);
        state.isDisabled = false;
        state.listBanners[index] = data;
      })
      .addCase(editBanner.rejected, (state) => {
        state.isDisabled = false;
      })
      .addCase(deleteBanner.fulfilled, (state, action) => {
        const { arg } = action.meta;
        const newData = state.listBanners.filter((data: any) => data.id !== arg);
        state.listBanners = newData;
      })
      .addCase(checkDateSlot.pending, (state, { meta }) => {
        const type = meta.arg.type;
        state.isDateLoading = true;

        if (type === "startDateSlot") {
          state.listStartDateSlot = [];
        }

        if (type === "endDateSlot") {
          state.listEndDateSlot = [];
        }
      })
      .addCase(checkDateSlot.fulfilled, (state, { payload, meta }) => {
        const type = meta.arg.type;
        state.isDateLoading = false;
        if (type === "startDateSlot") {
          state.listStartDateSlot = payload.data;
        }

        if (type === "endDateSlot") {
          state.listEndDateSlot = payload.data;
        }
      })
      .addCase(checkDateSlot.rejected, (state) => {
        state.isDateLoading = false;
      })
      .addCase(verifyBanner.pending, (state) => {
        state.isDisabled = true;
      })
      .addCase(verifyBanner.fulfilled, (state, { payload }) => {
        state.isDisabled = false;
        state.isValidate = true;
        state.listDateOff = payload.data ?? [];
      })
      .addCase(verifyBanner.rejected, (state) => {
        state.isValidate = false;
        state.isDisabled = false;
      });
  },
});
export const {
  handleDate,
  imageHandler,
  cleanData,
  onSelectProvince,
  onSelectRegency,
  onSelectDistrict,
  onSelectClient,
  onSelectGender,
  onSelectReligion,
  cleanListBanner,
  editAgain,
} = slice.actions;
export default slice.reducer;

export const getAllClient = createAsyncThunk("getAllClient", async () => {
  try {
    const { data } = await axiosInstance.get(`/clients`);
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const initialBannerForm = createAsyncThunk("initialBannerForm", async () => {
  try {
    const response = await axiosInstance.get(`/banner-form`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const checkDateSlot = createAsyncThunk(
  "checkDateSlot",
  async (params: { data: string; type: string }) => {
    try {
      const response = await axiosInstance.get(`/banner-slot?${params.data}`);
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
);

export const getBannerById = createAsyncThunk("getBannerById", async (id: string) => {
  try {
    const { data } = await axiosInstance.get(`/banner/${id}`);
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const getAllBanner = createAsyncThunk(
  "getAllBanner",
  async (params: { totalItem: number; currentPage: number }) => {
    try {
      const { data } = await axiosInstance.get(
        `/banner?totalItem=${params.totalItem}&page=${params.currentPage}`
      );
      return data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
);

export const createBanner = createAsyncThunk("createBanner", async (value: any) => {
  try {
    const { data } = await axiosInstance.post(`/banner`, value, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const editBanner = createAsyncThunk(
  "editBanner",
  async (params: { id: string; value: any }) => {
    try {
      const { data } = await axiosInstance.put(`/banner/${params.id}`, params.value, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
);

export const deleteBanner = createAsyncThunk("deleteBanner", async (id: string) => {
  try {
    const response = await axiosInstance.delete(`/banner/${id}`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const verifyBanner = createAsyncThunk("verifyBanner", async (value: any) => {
  try {
    const { data } = await axiosInstance.post(`/banner-verify-slot`, value);
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
});
