import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "src/utils/axios";

interface state {
  listRole: any[];
  listModuleAccess: any[];
  editMode: boolean;
  isLoading: boolean;
  isLoadingAccess: boolean;
  id: string;
  editValue: {};
}

const initialState: state = {
  listRole: [],
  listModuleAccess: [],
  editMode: false,
  isLoading: true,
  isLoadingAccess: true,
  id: "",
  editValue: {},
};

const moduleAccessSlice = createSlice({
  name: "client permission",
  initialState,
  reducers: {
    handleChecklist: (state, { payload }) => {
      const { moduleId, categoryId, buttonKey } = payload;
      const i = state.listModuleAccess.findIndex((val) => val.categoryId === categoryId);
      const j = state.listModuleAccess[i].listModules.findIndex((val: any) => val.id === moduleId);
      const checkValue = state.listModuleAccess[i].listModules[j][buttonKey];
      state.listModuleAccess[i].listModules[j][buttonKey] = checkValue === 0 ? 1 : 0;
      if (state.listModuleAccess[i].listModules[j]["canRead"] === 0) {
        state.listModuleAccess[i].listModules[j]["canCreate"] = 0;
        state.listModuleAccess[i].listModules[j]["canUpdate"] = 0;
        state.listModuleAccess[i].listModules[j]["canDelete"] = 0;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRoles.fulfilled, (state, { payload }) => {
        const { data } = payload;
        state.listRole = data;
        state.isLoading = false;
      })
      .addCase(getModuleByRole.pending, (state) => {
        state.isLoadingAccess = true;
      })
      .addCase(getModuleByRole.fulfilled, (state, { payload }) => {
        const { data } = payload;
        state.isLoadingAccess = false;
        state.listModuleAccess = data;
      })
      .addCase(getModuleByRole.rejected, (state) => {
        state.isLoadingAccess = false;
      })
      .addCase(editRoleAccess.pending, (state) => {
        state.isLoadingAccess = true;
      })
      .addCase(editRoleAccess.fulfilled, (state, { payload }) => {
        state.isLoadingAccess = false;
      })
      .addCase(editRoleAccess.rejected, (state) => {
        state.isLoadingAccess = false;
      });
  },
});

export const { handleChecklist } = moduleAccessSlice.actions;
export default moduleAccessSlice.reducer;

export const getRoles = createAsyncThunk("client/getRoles", async () => {
  try {
    const response = await axiosInstance.get("/client-roles");
    return response.data;
  } catch (err: any) {
    throw err.response.data;
  }
});

export const getModuleByRole = createAsyncThunk("client/getModuleByRole", async (id: string) => {
  try {
    const response = await axiosInstance.get(`/client-role-permission/${id}`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const editRoleAccess = createAsyncThunk(
  "client/editRoleAccess",
  async ({ id, listModuleAccess }: { id: string; listModuleAccess: any[] }, _) => {
    let newArray = [];

    try {
      for (const { listModules } of listModuleAccess) {
        newArray.push(...listModules);
      }

      const data = {
        id,
        listModules: newArray,
      };

      const response = await axiosInstance.put("/client-role-permission", data);
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
);
