import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "src/utils/axios";

interface State {
  listRole: any[];
  editMode: boolean;
  isLoading: boolean;
  isDisabled: boolean;
  showModal: boolean;
  id: string;
  editValue: {};
}

const initialState: State = {
  listRole: [],
  editMode: false,
  isLoading: true,
  isDisabled: false,
  showModal: false,
  id: "",
  editValue: {},
};

const roleSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    showPopupModal: (state) => {
      state.showModal = !state.showModal;
      state.id = "";
      state.editMode = false;
      state.editValue = {};
    },
    updatedRole: (state, { payload }) => {
      const [findRole] = state.listRole.filter((data: any) => data.id === payload);
      if (Object.keys(findRole).length > 0) {
        state.showModal = !state.showModal;
        state.id = findRole.id;
        state.editMode = true;
        state.editValue = findRole;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllRole.fulfilled, (state, { payload }) => {
        const { data } = payload;
        state.isLoading = false;
        state.listRole = data;
      })
      .addCase(getAllRole.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(createRole.pending, (state) => {
        state.isDisabled = true;
      })
      .addCase(createRole.fulfilled, (state, { payload }) => {
        const { data } = payload;
        state.isDisabled = false;
        state.listRole = [...state.listRole, data];
      })
      .addCase(createRole.rejected, (state) => {
        state.isDisabled = false;
      })
      .addCase(editedRole.pending, (state) => {
        state.isDisabled = true;
      })
      .addCase(editedRole.fulfilled, (state, { meta }) => {
        const { value, id } = meta.arg;
        const index = state.listRole.findIndex((data) => data.id === id);
        const newValue = { id, name: value.name };
        state.isDisabled = false;
        state.listRole[index] = newValue;
      })
      .addCase(editedRole.rejected, (state) => {
        state.isDisabled = false;
      })
      .addCase(deleteRole.fulfilled, (state, { meta }) => {
        const { arg } = meta;
        const newData = state.listRole.filter((data) => data.id !== arg);
        state.listRole = newData;
      });
  },
});
export const { showPopupModal, updatedRole } = roleSlice.actions;
export default roleSlice.reducer;

export const getAllRole = createAsyncThunk("getAllRole", async () => {
  try {
    const response = await axiosInstance.get("/roles");
    return response.data;
  } catch (err: any) {
    throw err.response.data;
  }
});

export const createRole = createAsyncThunk("createRole", async (data: any) => {
  try {
    const response = await axiosInstance.post("/roles", data);
    return response.data;
  } catch (err: any) {
    throw err.response.data;
  }
});

export const editedRole = createAsyncThunk(
  "editRole",
  async (data: { id: string; value: any }, _) => {
    try {
      const response = await axiosInstance.put(`/roles/${data.id}`, data.value);
      return response.data;
    } catch (err: any) {
      throw err.response.data;
    }
  }
);

export const deleteRole = createAsyncThunk("deleteRole", async (id: string) => {
  try {
    const response = await axiosInstance.delete(`/roles/${id}`);
    return response.data;
  } catch (err: any) {
    throw err.response.data;
  }
});
